import React from 'react'
import SectionHeader from '../../common/Header/SectionHeader'
import { processPoints } from './consts'
import WorkCycle from './WorkCycle/WorkCycle'
import useWorkCycleAnimation from '../../common/hooks/useWorkCycleAnimation'
import Footer from '../Footer/Footer'
import ProcessPoints from '../../common/Process/ProcessPoints'

const Process = () => {
  const { animationContainerRef, triggerRef } = useWorkCycleAnimation(true)

  return (
    <section>
      <SectionHeader wordsToType={['Process']} />
      <div ref={animationContainerRef}>
        <ProcessPoints points={processPoints} />
        <WorkCycle ref={triggerRef} />
        <Footer aria-hidden />
      </div>
    </section>
  )
}

export default Process
