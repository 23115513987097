import React from 'react'
import Layout from '../components/en/Layout/Layout'
import Process from '../components/en/Process/Process'

const ProcessPage = () => (
  <Layout
    title='Process | the.good.code; - High-Quality IT Solutions'
    description='Learn about the.good.code; a team of technology experts with international experience working for global leaders like Microsoft, Intel, P&G and innovative startups. Discover our mission, vision, and values.'
  >
    <Process />
  </Layout>
)

export default ProcessPage
