import React, { forwardRef } from 'react'
import ContentContainer from '../../../common/Layout/ContentContainer'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import WorkSteps from './WorkSteps'

const WorkCycle = forwardRef((props, triggerRef) => (
  <ContentContainer
    as='article'
    className='article-padding'
  >
    <div ref={triggerRef}>
      <TextWithDots className='mb-12'>work cycle</TextWithDots>
      <WorkSteps />
    </div>
  </ContentContainer>
))

WorkCycle.displayName = 'WorkCycle'

export default WorkCycle
