export const processPoints = [
  {
    id: '01',
    title: 'Building relationships',
    desc: 'The foundation of any project is the relationship between all parties. We start with a meeting where we can discuss your goals and what you desire to achieve to help us understand your vision.'
  },
  {
    id: '02',
    title: 'Gathering requirements',
    desc:
      'Next, we will discuss your existing team, technology stack and management frameworks. Then, we will agree on the scope and goals for cooperation and select best\n' +
      'suited cooperation model.\n'
  },
  {
    id: '03',
    title: 'Assembling the team and tech stack',
    desc:
      'With your goals in mind, now we can create a team tailored to your needs. For project cooperation\n' +
      'model, we also propose timeline and technology.'
  },
  {
    id: '04',
    title: 'Setting up work flow',
    desc:
      'We will discuss frequency of reporting, communication\n' +
      'channels, working hours and task management to create your desired work ow. This will then be summarised for you in a Statement of Work.\n'
  }
]
